import { FC, useEffect, useState, useMemo } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { IReservationForm, IShortForm } from '../type';
import { DATE_FORMAT, EVENTS } from '../../../constants';
import { useConfig } from '../../../context/ConfigProvider';
import { useUnitInformation } from '../../../context/UnitsInformationProvider';
import { TextMaskCustom } from '../../../components/PhoneNumberField';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { UnitTierInformation } from '../../../components/UnitTierInformation';

export const ShortForm: FC<Omit<IReservationForm<IShortForm>, 'typeOfForm'>> = ({
  formData,
  unitGroupInformation,
  onFormDataSubmit,
  onFormDataSecondarySubmit,
}) => {
  const { triggerEvent, configuration } = useConfig();
  const { reserving } = useUnitInformation();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IShortForm>({
    defaultValues: {
      firstName: formData?.firstName || '',
      lastName: formData?.lastName || '',
      email: formData?.email || '',
      phoneNumber: formData?.phoneNumber || '',
      moveInDate: formData?.moveInDate || moment().format(DATE_FORMAT),
      promoId: formData?.promoId || '',
    },
  });

  const [appliedPromo, setAppliedPromo] = useState<null | {
    name: string;
    description: string;
  }>(null);

  const [promoError, setPromoError] = useState(false);
  const [promoCodeState, setPromoCodeState] = useState('');

  const discountPlans = unitGroupInformation?.discountPlans || [];

  const handleApplyPromo = () => {
    const selectedPlan = discountPlans.find((plan) => {
      const isMatchingPlan = plan.name === promoCodeState;
      const isManualApply = !plan.autoApply;

      const isWithinDateRange =
        (!plan.startDate || new Date(plan.startDate) <= new Date()) &&
        (!plan.endDate || new Date(plan.endDate) >= new Date());

      return isMatchingPlan && isManualApply && isWithinDateRange;
    });

    if (selectedPlan) {
      setAppliedPromo({
        name: selectedPlan.name,
        description: selectedPlan.publicDescription || selectedPlan.description,
      });
      setValue('promoId', selectedPlan.id || '');
      setPromoError(false);
    } else {
      setPromoError(true);
      setValue('promoId', '');
    }
  };

  const resetPromoState = () => {
    setAppliedPromo(null);
    setPromoError(false);
    setValue('promoId', '');
  };

  const handleRemovePromo = () => {
    setPromoCodeState('');
    resetPromoState();
  };

  // Reset promo state when the user modifies the promo code
  useEffect(() => {
    resetPromoState();
  }, [promoCodeState]);

  const onSubmit: SubmitHandler<IShortForm> = onFormDataSubmit;

  useEffect(() => {
    triggerEvent(EVENTS.ON_RESERVATION_FORM_VALIDATION_ERROR, errors);
  }, [errors]);

  const availableTiers = useMemo(
    () =>
      unitGroupInformation.tiers.filter(
        (x) => x.availableUnitsCount >= 1
      ),
    [unitGroupInformation]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Controller
          name="unitGroupTierId"
          rules={{
            required: {
              value: availableTiers.length > 0,
              message: "Unit Group Tier needs to be selected",
            },
          }}
          control={control}
          render={({ field }) => (
            <Grid item xs={12}>
              {availableTiers.map((tier) => (
                <UnitTierInformation
                  key={tier.id}
                  tierInfo={tier}
                  hasError={Boolean(errors.unitGroupTierId)}
                  selected={field.value === tier.id}
                  // selected={props.selectedTier === tier.id}
                  onSelect={() => {
                    triggerEvent(EVENTS.ON_UNITTIER_SELECT, {
                      unitTierId: tier.id,
                    });
                    field.onChange(tier.id);
                  }}
                />
              ))}
              {errors.unitGroupTierId && (
                <Typography variant='body2' color='error'>
                  {errors.unitGroupTierId.message}
                </Typography>
              )}
            </Grid>
          )}
        />
        <Controller
          name='promoId'
          control={control}
          render={({ field }) => <input type='hidden' {...field} />}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Stack
            direction={{ xs: 'row', md: 'row' }}
            spacing={2}
            alignItems='flex-start' // Align items at the top
            width='100%'
          >
            <TextField
              fullWidth
              label='Promo Code'
              placeholder='Promo Code'
              value={promoCodeState}
              onChange={(e) => setPromoCodeState(e.target.value)}
              error={promoError}
              InputProps={{
                readOnly: !!appliedPromo,
              }}
              helperText={
                promoError ? (
                  'This code is not recognized. Please check your code and try again.'
                ) : appliedPromo ? (
                  <span style={{ color: 'green' }}>
                    {appliedPromo.name} was successfully applied.
                    <br />
                    {appliedPromo.description}
                  </span>
                ) : (
                  ' '
                )
              }
              FormHelperTextProps={{
                sx: {
                  minHeight: '3em',
                },
              }}
              sx={{
                width: {
                  xs: '80%', // Full width on mobile
                  md: '40%', // 40% width on desktop and tablet
                },
                '& .MuiOutlinedInput-root': {
                  ...(appliedPromo && {
                    backgroundColor: 'rgba(0, 255, 0, 0.1)', // Light green background on success
                    '& fieldset': {
                      borderColor: 'green',
                    },
                    '&:hover fieldset': {
                      borderColor: 'green',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'green',
                    },
                  }),
                  ...(!!appliedPromo && {
                    '& input': {
                      cursor: 'default',
                    },
                  }),
                },
              }}
            />
            <Button
              variant='outlined'
              onClick={appliedPromo ? handleRemovePromo : handleApplyPromo}
              disabled={!promoCodeState}
              sx={{
                height: '56px', // Dynamically matching the TextField height
                minWidth: '120px', // Consistent button width
                color: 'primary.main',
                borderColor: 'primary.main',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderColor: 'primary.main',
                },
                '&.Mui-disabled': {
                  color: 'primary.main',
                  borderColor: 'primary.main',
                },
              }}
            >
              {appliedPromo ? 'Remove Code' : 'Apply'}
            </Button>
          </Stack>
        </Grid>
        <Typography
          variant='h6'
          gutterBottom
          sx={{ marginLeft: '16px', marginTop: '4px' }}
        >
          Contact Information
        </Typography>
        <Controller
          name='firstName'
          rules={{
            required: { value: true, message: 'First Name is required' },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete='given-name'
                label='First Name'
                placeholder='First Name'
                required
                error={errors.firstName ? true : undefined}
                helperText={errors.firstName?.message || ''}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name='lastName'
          rules={{
            required: { value: true, message: 'Last Name is required' },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete='family-name'
                label='Last Name'
                placeholder='Last Name'
                required
                error={errors.lastName ? true : undefined}
                helperText={errors.lastName?.message || ''}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name='email'
          rules={{
            required: { value: true, message: 'Email is required' },
            pattern: {
              value:
                /^(?!\.)(?!.*\.{2})([a-zA-Z0-9._+-]+(?<!\.))@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete='email'
                label='Email'
                placeholder='Email'
                required
                error={errors.email ? true : undefined}
                helperText={errors.email?.message || ''}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name='phoneNumber'
          rules={{
            required: { value: true, message: 'Phone Number is required' },
            maxLength: { value: 14, message: 'Invalid Phone Number' },
          }}
          control={control}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Grid item xs={12}>
              {/* <FormControl
                variant="outlined"
                error={errors.phoneNumber ? true : undefined}
                // helperText={errors.phoneNumber?.message || ""}
              >
                <InputLabel htmlFor="formatted-text-mask-input">
                  Phone Number
                </InputLabel>
                <Input
                  fullWidth
                  inputRef={ref}
                  {...otherFieldProps}
                  autoComplete="tel"
                  required
                  placeholder="Phone Number"
                  id="formatted-text-mask-input"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={TextMaskCustom as any}
                />
              </FormControl> */}
              {/* <Box sx={{ display: "none" }}>
                <Input
                  fullWidth
                  inputRef={ref}
                  {...otherFieldProps}
                  autoComplete="tel"
                  required
                  placeholder="Phone Number"
                  id="formatted-text-mask-input"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent={TextMaskCustom as any}
                />
              </Box> */}
              <TextField
                fullWidth
                inputRef={ref}
                autoComplete='tel'
                label='Phone Number'
                placeholder='Phone Number'
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                InputProps={{ inputComponent: TextMaskCustom as any }}
                required
                error={errors.phoneNumber ? true : undefined}
                helperText={errors.phoneNumber?.message || ''}
                {...otherFieldProps}
              />
            </Grid>
          )}
        />
        <Controller
          name='moveInDate'
          control={control}
          rules={{
            required: { value: true, message: 'Move in date is required' },
            validate: (value) => {
              if (!value) return true;
              // if(moment(value, DATE_FORMAT).isValid())
              //   return "Move in date should be valid date";
              if (moment(value, DATE_FORMAT).isBefore(moment(), 'date'))
                return 'Move in date should be today or after today';
            },
          }}
          render={({ field: { ref, onChange, value, ...otherProps } }) => (
            <Grid item xs={12}>
              <DatePicker
                label='Move-in Date'
                {...otherProps}
                inputRef={ref}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    placeholder: 'Move-in Date',
                    error: errors.moveInDate ? true : undefined,
                    helperText: errors.moveInDate?.message || '',
                    inputRef: ref,
                    required: true,
                  },
                  inputAdornment: { position: 'start' },
                }}
                maxDate={moment().add(1, 'month')}
                minDate={moment()}
                value={moment(value, DATE_FORMAT)}
                onChange={(data) => data && onChange(data.format(DATE_FORMAT))}
              />
            </Grid>
          )}
        />
        {configuration.enableMoveIn && (
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={reserving}
              variant='contained'
              onClick={handleSubmit(onFormDataSubmit)}
            >
              Rent Now
              {reserving && <CircularProgress size={20} sx={{ mx: 1 }} />}
            </Button>
          </Grid>
        )}
        {!configuration.forceMoveIn && (
          <Grid item xs={12}>
            <Button
              disabled={reserving}
              fullWidth
              onClick={handleSubmit(onFormDataSecondarySubmit!)}
            >
              Reserve this unit
              {reserving && <CircularProgress size={20} sx={{ mx: 1 }} />}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
