import { Post } from "../utils/Api";

export interface IReservationRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  unitGroupId: string; // if you send this alone any available unit will be reserved for this unitgroup
  unitGroupTierId?: string; // if you send this along with the unitGroupId that particular unit will be reserved
  isReservation: boolean;
  desiredMoveInDate?: string;
  notes?: string;
  onlineMoveInUrl: string;
  discountPlans?: string[];
}

export interface IReservationResponse {
  id: string;
  reservationId: string;
  alias: string;
  contactId: string;
  softwareProvider: string;
  facilityId: string;
  companyId: string;
  unitId: string;
  createdAt: string;
  updatedAt: string;
  notes?: string;
}

export const CreateReservation = (data: IReservationRequest) =>
  Post<IReservationResponse, IReservationRequest>("/v1/leads", data);
